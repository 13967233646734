import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Rater from "react-rater";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import SEO from "../components/seo";
import TextareaInput from "../components/inputs/textareaInput";
import { changeRatingFormValue, getOrderRatingAction, rateOrderAction } from "../state/rating.reducer";
import { clearLayoutAction, setLayoutAction } from "../state/layout.reducer";
import { getQueryParam } from "../utils/queryParams";
import AppPageHeader from "../components/common/texts/appPageHeader";
import { Loading } from "../components/common/loading";
import AppLeadingText from "../components/common/texts/appLeadingText";
import AppButton from "../components/common/button";
import { LINKS } from "../constants/links";
import createRipple from "../components/common/ripple";
import { AppContainer } from "../components/common/appContainer";

const StyledRestpayStarsWrapper = styled.div`
	margin: 0 auto;
	width: 70%;
	.react-rater {
		color: ${({ theme }) => theme.colors.brandBlue};
		display: flex;
		font-size: 55px;
		justify-content: center;
		width: 100%;
	}
`;
const StyledInputWrapper = styled.div`
	margin: 50px 0 0;
`;
const StyledButtonsWrapper = styled.div`
	display: block;
	padding-bottom: 25px;
	padding-top: 25px;
	text-align: center;
`;
const StyledLinkButton = styled(AppButton)`
	border: none;
	color: ${({ theme }) => theme.colors.fontGray};
	font-size: 1rem;
	font-weight: 400;
	position: relative;
	text-transform: lowercase;
	&.active,
	&:hover {
		background-color: #fff;
		&:hover,
		&:active {
			color: ${({ theme }) => theme.colors.fontGray};
		}
	}
`;
const StyledRatingsWrapper = styled.div`
	margin: -40px 0 40px;
`;
const StyledRatingDescription = styled.p`
	color: ${({ theme }) => theme.colors.fontGray};
	font-size: 16px;
	margin: 30px 0 0;
	text-align: center;
`;

const RatingPage = () => {
	const { t } = useTranslation();
	const ratingReducer = useSelector((state) => state.rating);
	const dispatch = useDispatch();
	const orderId = getQueryParam("orderId");

	const { foodRating, serviceRating, rating, comment } = ratingReducer.body;
	const isValid = rating > 0 && foodRating > 0 && serviceRating > 0;

	useEffect(() => {
		dispatch(getOrderRatingAction(orderId));
		dispatch(setLayoutAction({ backTo: LINKS.ORDER + orderId }));

		return () => {
			dispatch(clearLayoutAction());
		};
	}, [orderId]);

	const handleChange = (name) => (value) => {
		dispatch(changeRatingFormValue(name, value));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(rateOrderAction(orderId, foodRating, serviceRating, rating, comment));
	};

	const editing = !!ratingReducer.editing;

	return (
		<>
			<SEO title={t("rating.title")} />
			<AppContainer>
				{ratingReducer.loading && <Loading transparentBg />}
				<AppPageHeader>{t(!editing ? "rating.header" : "rating.headerEdit")}</AppPageHeader>
				<AppLeadingText>{t(!editing ? "rating.description" : "rating.descriptionEdit")}</AppLeadingText>
				<form autoComplete="off" onSubmit={handleSubmit}>
					<StyledRatingsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.foodRatingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("foodRating")(rating)}
								rating={foodRating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.serviceRatingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("serviceRating")(rating)}
								rating={serviceRating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
						<StyledRestpayStarsWrapper>
							<StyledRatingDescription>{t("paymentSuccess.ratingTitle")}</StyledRatingDescription>
							<Rater
								onClick={createRipple}
								onRate={({ rating }) => handleChange("rating")(rating)}
								rating={rating}
								total={5}
							/>
						</StyledRestpayStarsWrapper>
					</StyledRatingsWrapper>
					<StyledInputWrapper>
						<TextareaInput
							autoComplete="off"
							id="comment"
							inputChanged={handleChange("comment")}
							placeholder={t("paymentSuccess.inputPlaceholder")}
							type="text"
							value={comment}
						/>
					</StyledInputWrapper>
					<StyledButtonsWrapper>
						<AppButton as="button" disabled={!isValid}>
							{t(!editing ? "common.send" : "rating.saveEdit")}
						</AppButton>
						<StyledLinkButton as={Link} onClick={createRipple} to={LINKS.ORDER + orderId}>
							{t("common.goBack")}
						</StyledLinkButton>
					</StyledButtonsWrapper>
				</form>
			</AppContainer>
		</>
	);
};

export default RatingPage;
